import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import FeatureSectionWrapper from './featureSection.style';
import ctaImg1 from '../../../images/save-money-icon.png';
import ctaImg2 from '../../../images/risk-icon.png';
import ctaImg3 from '../../../images/free-fee-icon.png';
import ctaImg4 from '../../../images/test-account-icon.png';

const FeatureSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  contentStyle,
  blockWrapperStyle
}) => {
  const { t } = useTranslation();

  const ctaList = [
    {
      id: 1,
      icon: ctaImg1,
      title: t('home.cta.cta1.title'),
      description: t('home.cta.cta1.desc')
    },
    {
      id: 2,
      icon: ctaImg2,
      title: t('home.cta.cta2.title'),
      description: t('home.cta.cta2.desc')
    },
    {
      id: 3,
      icon: ctaImg3,
      title: t('home.cta.cta3.title'),
      description: t('home.cta.cta3.desc')
    },
    {
      id: 4,
      icon: ctaImg4,
      title: t('home.cta.cta4.title'),
      description: t('home.cta.cta4.desc')
    }
  ];
  return (
    <FeatureSectionWrapper id="key-features">
      <Container>
        <Box {...sectionHeader} className="sectionHeader">
          <Text content={t('home.cta.topic')} {...sectionSubTitle} />
          <Heading content={t('home.cta.desc')} {...sectionTitle} />
        </Box>
        <Box className="row" {...row}>
          {ctaList.map((cta, index) => (
            <Box className="col" {...col} key={index}>
              <FeatureBlock
                icon={<img src={cta.icon} alt="cta" />}
                wrapperStyle={blockWrapperStyle}
                contentStyle={contentStyle}
                title={<Heading content={cta.title} {...featureTitle} />}
                description={
                  <Text content={cta.description} {...featureDescription} />
                }
                className="cryptoFeature"
              />
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '40px', '40px', '80px'],
    display: 'flex',
    width: '100%'
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['30px', '40px'],
    fontWeight: '300',
    letterSpacing: '-0.025em',
    color: '#fff',
    mb: '15px'
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['14px', '16px'],
    fontWeight: '400',
    color: '#496b96',
    mb: '0',
    maxWidth: '420px',
    lineHeight: '1.5'
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap'
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 4, 1 / 4]
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px']
  },

  // feature content default style
  contentStyle: {
    textAlign: 'center'
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#fff',
    lineHeight: '1.5',
    mb: ['10px', '10px', '10px', '15px'],
    letterSpacing: '-0.025em',
    mt: ['15px', '15px', '15px', '25px']
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.6',
    color: 'rgba(142, 199, 255, 0.502)'
  }
};

export default FeatureSection;
