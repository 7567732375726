import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'common/src/components/Text';
import Fade from 'react-reveal/Fade';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import SectionWrapper, { ContentWrapper } from './walletSection.style';
import mobileHistoryImg from '../../../images/mobile-app-history-page.png';
import BtnIcon1 from '../../../images/apple-icon.png';
import BtnIcon2 from '../../../images/google-play-icon.png';
import iconImg1 from '../../../images/download-app-icon.png';
import iconImg2 from '../../../images/survey-icon.png';
import iconImg3 from '../../../images/risk-icon.png';

const WalletPortal = () => {
  const { t } = useTranslation();
  const iconList = [
    {
      id: 1,
      icon: iconImg1,
      title: t('home.section3.list1')
    },
    {
      id: 2,
      icon: iconImg2,
      title: t('home.section3.list2')
    },
    {
      id: 3,
      icon: iconImg3,
      title: t('home.section3.list3')
    }
  ];
  return (
    <SectionWrapper id="wallet">
      <Container>
        <ContentWrapper>
          <div className="image">
            <Image src={mobileHistoryImg} alt="One Option mobile app" />
          </div>
          <div className="content">
            <Heading content={t('home.section3.title')} />
            <Text content={t('home.section3.title2')} />
            <div className="walletfeatures">
              <Fade up>
                {iconList.map((item, index) => (
                  <FeatureBlock
                    key={`feature_point-${index}`}
                    icon={<img src={item.icon ? item.icon : ''} alt="icon " />}
                    iconPosition="left"
                    title={<Text content={item.title} />}
                  />
                ))}
              </Fade>
            </div>
            <div className="btnGroups">
              <Button
                title="Apple Store"
                variant="textButton"
                icon={<img src={BtnIcon1} alt="icon" />}
                iconPosition="left"
                className="appStore"
              />
              <Button
                title="Google Play"
                variant="textButton"
                icon={<img src={BtnIcon2} alt="icon" />}
                iconPosition="left"
                className="playStore"
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=co.oneoption'
                  )
                }
              />
            </div>
            <Text className="windowsAllert" content={t('home.section3.desc')} />
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default WalletPortal;
