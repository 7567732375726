import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from '../containers/CryptoModern/Banner';
import Features from '../containers/CryptoModern/FeatureSection';
import Investment from '../containers/CryptoModern/Investment';
import Promotions from '../containers/Custom/Promotions';
import ActiveUser from '../containers/Custom/ActiveUser';
import WalletSection from '../containers/CryptoModern/WalletSection';
import MainLayout from '../layouts/main';

export default function() {
  const { t } = useTranslation();
  return (
    <MainLayout title={t('home.title')}>
      <Banner />
      <Features />
      <Investment />
      <Promotions />
      <ActiveUser />
      <WalletSection />
    </MainLayout>
  );
}
