import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './investment.style';
import companyImg from '../../../images/company.png';

const InvestmentPortal = () => {
  const { t } = useTranslation();
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading content={t('home.section1.title')} />
            <Text content={t('home.section1.desc')} />
          </div>
          <div className="image">
            <Image src={companyImg} alt="Company Image" />
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default InvestmentPortal;
