import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './promotions.style';
import computerImg from '../../../images/computer-billing.png';

const DesignedAndBuilt = () => {
  const { t } = useTranslation();
  return (
    <SectionWrapper id="fund">
      <Container>
        <ContentWrapper>
          <div className="image">
            <Image src={computerImg} alt="Computer billing" />
          </div>
          <div className="content">
            <Heading content={t('home.section2.title')} />

            <Heading
              content={t('home.section2.title2')}
              style={{ fontSize: '28px', marginBottom: '20px' }}
            />
            <Heading
              content={t('home.section2.title3')}
              style={{
                fontSize: '20px',
                marginBottom: '10px',
                lineHeight: '30px'
              }}
            />

            <hr color={'#496b96'} />
            <Text content={t('home.section2.list1')} />
            <Text content={t('home.section2.list2')} />
            <Text content={t('home.section2.list3')} />
          </div>
          <div className="gradientDiv"> </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default DesignedAndBuilt;
