import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import ActiveUserWrapper from './activeUser.style';
import img1 from '../../../images/authentication.png';
import img2 from '../../../images/download-app-icon.png';

const getRamdomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

const ActiveUserSection = ({ col, title, userAmout }) => {
  // const [amountOnlineUser, setAmountOnlineUser] = useState(0);
  const { t } = useTranslation();
  const amountActiveUser = 12261;
  const amountOnlineUser = getRamdomNumber(1500, 1600);
  // useEffect(() => {
  //   const id = setInterval(() => {
  //     console.log('tset');
  //     const newUserNumber = getRamdomNumber(1500, 1600);
  //     setAmountOnlineUser(newUserNumber);
  //   }, 1000);

  //   return clearInterval(id);
  // }, []);
  return (
    <ActiveUserWrapper id="active-user-section">
      <Container>
        <Box
          className="row"
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box {...col}>
            <img src={img1} alt={t('home.section4.userAmout1')} />
            <Heading content={t('home.section4.userAmout1')} {...title} />
            <Heading content={amountActiveUser} {...userAmout} />
          </Box>
          <Box {...col}>
            <img src={img2} alt={t('home.section4.userAmout2')} />
            <Heading content={t('home.section4.userAmout2')} {...title} />
            <Heading content={amountOnlineUser} {...userAmout} />
          </Box>
        </Box>
      </Container>
    </ActiveUserWrapper>
  );
};

// ActiveUserSection style props
ActiveUserSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  title: PropTypes.object,
  featureDescription: PropTypes.object
};

// ActiveUserSection default style
ActiveUserSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '40px', '40px', '80px'],
    display: 'flex',
    width: '100%'
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['30px', '40px'],
    fontWeight: '300',
    letterSpacing: '-0.025em',
    color: '#fff',
    mb: '15px'
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['14px', '16px'],
    fontWeight: '400',
    color: '#496b96',
    mb: '0',
    maxWidth: '420px',
    lineHeight: '1.5'
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap'
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 4, 1 / 4]
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px']
  },

  // feature content default style
  contentStyle: {
    textAlign: 'center'
  },
  // feature title default style
  title: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#fff',
    lineHeight: '1.5',
    mb: ['10px', '10px', '10px', '15px'],
    letterSpacing: '-0.025em',
    mt: ['15px', '15px', '15px', '25px']
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.6',
    color: 'rgba(142, 199, 255, 0.502)'
  },
  userAmout: {
    color: 'white',
    fontSize: ['32px', '34px']
  }
};

export default ActiveUserSection;
